.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  touch-action: auto;
}

.ps__rail-x,
.ps__rail-y {
  display: none;
  opacity: 0;
  transition: $perfectScrollbar-ps-ps__rail-x-y-transition-opacity-bg;
  position: absolute;
  z-index: $perfectScrollbar-ps-z-index;
}

.ps__rail-x {
  height: $perfectScrollbar-ps-ps__rail-x-y-length;
  bottom: 0;
}

.ps__rail-y {
  width: $perfectScrollbar-ps-ps__rail-x-y-length;
  right: 0;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > {
  .ps__rail-x,
  .ps__rail-y {
    opacity: $perfectScrollbar-ps-ps__rail-x-y-opacity;
  }
}

.ps--focus > {
  .ps__rail-x,
  .ps__rail-y {
    opacity: $perfectScrollbar-ps-ps__rail-x-y-opacity;
  }
}

.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: $perfectScrollbar-ps-ps__rail-x-y-opacity;
}

.ps {
  .ps__rail-x:hover,
  .ps__rail-y:hover,
  .ps__rail-x:focus,
  .ps__rail-y:focus,
  .ps__rail-x.ps--clicking,
  .ps__rail-y.ps--clicking {
    background-color: $perfectScrollbar-ps-ps__rail-x-y-bg-color;
    opacity: $perfectScrollbar-ps-ps__rail-x-y-hover-opacity;
  }
}

/*
 * Scrollbar thumb styles
 */

.ps__thumb-x,
.ps__thumb-y {
  background-color: $perfectScrollbar-ps-ps__thumb-x-y-color;
  border-radius: $perfectScrollbar-ps-ps__thumb-x-y-border-radius;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-x {
  transition: $perfectScrollbar-ps-ps__rail-x-transition-height-bg;
  height: $perfectScrollbar-ps-ps__thumb-x-y-length;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: $perfectScrollbar-ps-ps__thumb-x-y-position-length;
}

.ps__thumb-y {
  transition: $perfectScrollbar-ps-ps__rail-y-transition-width-bg;
  width: $perfectScrollbar-ps-ps__thumb-x-y-length;
  /* there must be 'right' for ps__thumb-y */
  right: $perfectScrollbar-ps-ps__thumb-x-y-position-length;
}

.ps__rail-x {
  &:hover > .ps__thumb-x,
  &:focus > .ps__thumb-x,
  &.ps--clicking .ps__thumb-x {
    background-color: $perfectScrollbar-ps-ps__rail-x-y-clicking;
    height: $perfectScrollbar-ps-ps__rail-x-y-clicking-length;
  }
}

.ps__rail-y {
  &:hover > .ps__thumb-y,
  &:focus > .ps__thumb-y,
  &.ps--clicking .ps__thumb-y {
    background-color: $perfectScrollbar-ps-ps__rail-x-y-clicking;
    width: $perfectScrollbar-ps-ps__rail-x-y-clicking-length;
  }
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
