$primary: #26354d;
$secondary: #202938;
$lightred: #ed1c24;
$darkred: #c1272d;
$skin: #f1c5b4;
$offwhite: #e6e6e6;
$bg_white: #f5f5f5;

.wizardWaiter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100vw;
  height: 380px;
  overflow: hidden;
}


.scene {
  display: flex;
}

/** Wizard Butler Build */

.wizard {
  position: relative;
  left: -200px;
  width: 190px;
  height: 240px;
  //border: 1px solid 
}

.body {
  position: absolute;
  bottom: 0px;
  left: 70px;
  height: 40px;
  width: 105px;
  border-bottom-left-radius: 40px;
  border-radius: 30px;
  background: $primary;
  &::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 130px;
  width: 50px;
  border-bottom-left-radius: 40px;
  transform: rotate(6deg);
  border-radius: 30px;
  background: $primary;
  }
    &::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 28px;
  height: 130px;
  width: 50px;
  border-bottom-left-radius: 40px;
  transform: rotate(-30deg);
  border-radius: 15px;
  background: $primary;
  }

  }

.chest {
  position: absolute;
  bottom: 50px;
  left: 80px;
  height: 90px;
  width: 40px;
  border-bottom-left-radius: 40px;
  border-radius: 30px;
  background: $offwhite;
  animation: chest_anim 10s ease-in-out infinite;
  
}
.rightarm {
  position: relative;
  top: 130px;
  left: 130px;
  width: 10px;
  height: 10px;
  //border: 1px solid; 
  animation: right_arm 10s ease-in-out infinite;
};

.rightsleeve {
  position: absolute;
  top: 30px;
  left: -20px;
  height: 50px;
  width: 40px;
  border-bottom-left-radius: 60px;
  border-radius: 30px;
  background: $secondary;
  transform-origin: 40px 0px;
  &::before {
  content: "";
  position: absolute;
  bottom: 20px;
  left: 0px;
  height: 70px;
  width: 40px;
  border-top-left-radius: 100px;
  border-top-right-radius: 15px;
 //border-radius: 15px;
  background: $secondary;
  };
.righthand {
    position: absolute;
    left: 5px;
    top: 15px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background: $skin;
    //transform: rotate(10deg);
    
    }
 
.rightfinger {
      position: relative;
      right: 10px;
      top: 20px;
      width: 30px;
      height: 15px;
      border-radius: 20px;
      background: $skin;
      /** Animation */
      transform-origin: right bottom;
      animation: right_finger 10s ease-in-out infinite;
    }
  };

.leftarm {
  position: absolute;
  bottom: 85px;
  left: 26px;
  height: 44px;
  width: 70px;
  background: $secondary;
  border-bottom-left-radius: 18px;
  /** animation */
  transform-origin: 60px 26px;
  transform: rotate(-70deg);
  animation: left_arm 10s ease-in-out infinite;
  .lefthand {
    position: absolute;
    left: -18px;
    top: 0;
    width: 18px;
    height: 30px;
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
    background: $skin;
    &::after {
      content: "";
      position: absolute;
      right: 3px;
      top: 0;
      width: 32px;
      height: 15px;
      border-radius: 20px;
      background: $skin;
      /** Animation */
      transform-origin: right bottom;
      transform: scaleX(0);
      animation: left_finger 10s ease-in-out infinite;
    }
  }
};

.head {
  position: absolute;
  top: -20px;
  left: 25px;
  width: 130px;
  height: 210px;
  /** Animation */
  transform-origin: center center;
  transform: rotate(-3deg);
  animation: head 10s ease-in-out infinite;
  
  }
.ear {
    position: relative;
    top: 25px;
    left: 105px;
    height: 20px;
    width: 20px;
    background-color: $skin;
    border-radius: 50%;
  
  }
.moustache{
            width:180px;
            height:180px;
            top: 20px;
            left:-55px;
            border-radius:50%;
            position:absolute;
            color:black;
            box-shadow:
            150px 240px 0 0 $primary,
            300px 240px 0 0 $primary;
            transform: rotate(110deg);
            transform: scale(.06);
        }
        .moustache::before{
            content:"";
            position:absolute;
            left:30px;
            top:120px;
            width:210px;
            height:290px;
            border-bottom:solid 180px $primary;
            border-radius:0 0 0 100%;
            transform:rotate(-25deg);
            transform-origin:right 210px;
        }
        .moustache::after{
            content:"";
            position:absolute;
            left:390px;
            top:120px;
            width:210px;
            height:290px;
            border-bottom:solid 180px $primary;
            border-radius:0 0 100% 0;
            transform:rotate(40deg);
            transform-origin:left 210px;
        }
  .face {
    position: relative;
    top: 65px;
    left: 30px;
    height: 90px;
    width: 90px;
    background-color: $skin;
    border-radius: 50%;
    
}

  .hat {
    position: absolute;
    height: 15px;
    bottom: 100px;
    left: 40px;
    border-left: 45px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 120px solid #555;
    border-color: transparent transparent $primary transparent;
    transform: rotate(10deg);
    
    }
  .hat-fold {
    position: absolute;
    bottom: -39px;
    left: 7px;
    height: 50px;
    width: 50px;
    background: $secondary;
    transform: rotate(-23deg);
    //transform: scale(1.2);
    clip-path: polygon(85% 17%, 93% 30%, 100% 76%, 60% 44%, 28% 54%, 0 100%, 0 0, 36% 0, 55% 1%, 72% 7%); 
}
.hat-rim {
  position: absolute;
  top: -15px;
  right: 78px;
  border-radius: 25px;
  background: $secondary;
  padding: 5px;
  width: 150px;
  height: 1px;
  transform: rotate(-180deg);
  transform-origin:right 70px;
  
};
.bowtie {
  position: relative;
  top: 75px;
  left: 20px;
  width: 40px;
  height: 30px;
  //border: 1px solid 
};

.bowtie1 {
  position: absolute;
  top: -3px;
  left: 2px;
  height: 32px;
  width: 35px;
  background: $darkred;
  clip-path: polygon(30% 20%, 50% 50%, 70% 20%, 100% 10%, 100% 90%, 70% 80%, 50% 50%, 30% 80%, 0 90%, 0 10%); 
   
}
.bowtie2 {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 26px;
  width: 40px;
  background: $lightred;
  clip-path: polygon(30% 20%, 50% 50%, 70% 20%, 100% 10%, 100% 90%, 70% 80%, 50% 50%, 30% 80%, 0 90%, 0 10%); 

   
}

.bowtie3 {
  position: absolute;
  top: -6px;
  left: 0px;
  height: 40px;
  width: 40px;
  background: $darkred;
  clip-path: circle(18% at 50% 50%);
   
}

.button1 {
  position: absolute;
  bottom: 3px;
  left: 0px;
  height: 26px;
  width: 40px;
  background: $primary;
  clip-path: circle(15% at 50% 50%);
   /** Animation */
  transform: rotate(-3deg);
  
   
}
.button2 {
  position: absolute;
  bottom: 22px;
  left: 0px;
  height: 26px;
  width: 40px;
  background: $primary;
  clip-path: circle(15% at 50% 50%);
  animation: button_2 10s ease-in-out infinite;
   
};

/** Objects for Tea */

.tray {
  position: relative;
  width: 200px;
  height: 100px;
  top: 160px;
  left: 185px;
  //border: 1px solid;
  
  animation: tray_float 10s ease-in-out infinite;
 
};
 
  .traybase {
  position: absolute;
  bottom: -50px;
  left: -10px;
  height: 230px;
  width: 230px;
  background: $secondary;
  clip-path: ellipse(40% 10% at 50% 50%);
  transform: rotate(0deg);     
 };
  .trayrim {
  position: absolute;
  bottom: -50px;
  left: -20px;
  height: 250px;
  width: 250px;
  background: $primary;
  clip-path: ellipse(40% 10% at 50% 50%);
  transform: rotate(0deg);     
 };

.teapot {
  position: relative;
  left: -100px;
  top: 0px;
  width: 240px;
  height: 190px;
  //border: 1px solid;
  transform-origin:25 85;
  
  animation: teapot_float 10s ease-in-out infinite;
 
};

.teabody {
  position: absolute;
  bottom: 0px;
  left: 70px;
  height: 85px;
  width: 85px;
  border-radius: 50px;
  background: $lightred;
}
  .handle {
  position: absolute;
  bottom: 0px;
  left: 30px;
  height: 100px;
  width: 100px;
  background: $lightred;
  clip-path: ellipse(40% 25% at 50% 50%);
  transform: rotate(-320deg);
    &::after {
      content: "";
      position: absolute;
      right: 28px;
      top: 26px;
      width: 55px;
      height: 55px;
      border-radius: 20px;
      background: $bg_white;
      clip-path: ellipse(40% 25% at 50% 50%);
      transform: rotate(15deg);   
    }
 };

  .spout1 {
   position: absolute;
  bottom: 5px;
  left: 80px;
  height: 60px;
  width: 100px;
  background: $lightred;
  clip-path: ellipse(40% 40% at 50% 50%);
  transform: rotate(330deg);
   
 };
 .spout2 {
   position: absolute;
  bottom: 12px;
  left: 105px;
  height: 80px;
  width: 110px;
  background: $lightred;
  clip-path: ellipse(18% 10% at 50% 50%);
  transform: rotate(105deg);
   
 };
 .spout3 {
   position: absolute;
  bottom: 20px;
  left: 115px;
  height: 90px;
  width: 100px;
  background: $lightred;
  clip-path: ellipse(22% 12% at 50% 50%);
  transform: rotate(150deg);
}
   .spout4 {
      content: "";
      position: absolute;
      right: 45px;
      top: 117px;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      background: $bg_white;
      clip-path: ellipse(30% 50% at 50% 50%);
      transform: rotate(30deg);
   
 };

.top {
      position: absolute;
      left: 102px;
      top: 90px;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background: $darkred
    }
.teabase {
    position: absolute;
    left: 85px;
    top: 172px;
    width: 55px;
    height: 25px;
    clip-path: ellipse(50% 50% at 50% 50%);
    border-radius: 100px;
    background: $darkred;
}

.lid {
    position: absolute;
    left: 80px;
    top: 105px;
    width: 65px;
    height: 15px;
    clip-path: ellipse(40% 50% at 50% 50%);
    border-radius: 100px;
    background: $darkred;
};

.teastream {
  position: relative;
  left: 95px;
  top: 22px;
  width: 50px;
  height: 50px;
  //border: 1px solid;
  animation: tea_float 10s ease-in-out infinite;
}

.teapour {
  position: absolute;
  top: -20px;
  left: -20px;
  height: 85px;
  width: 85px;
  background: $bg_white;
  transform-origin: center center;
  clip-path: ellipse(40% 25% at 50% 50%);
  transform: rotate(30deg);
     &::after {
      content: "";
      position: absolute;
      right: 70px;
      top: 0px;
      width: 30px;
      height: 40px;
      transform-origin: center center;
      background: #c47535;
      clip-path: ellipse(50% 50% at 50% 50%);
      transform: rotate(15deg);
      animation: tea_pour 10s ease-in-out infinite;
  }
};

.teacover1 {
  position: absolute;
  top: -10px;
  left: -40px;
  height: 85px;
  width: 85px;
  background: $bg_white;
  transform-origin: center center;
  clip-path: ellipse(40% 25% at 50% 50%);
  transform: rotate(90deg);
};

.cup{
  position: relative;
  top: 125px;
  right: 202px;
  width: 100px;
  height: 100px;
  //border: 1px solid;
  animation: cup_float 10s ease-in-out infinite;
};

.cupbody {
        position: absolute;
        top: 25px;
        right: 30px;
        //transform: translate(-50%, -50%);
        height: 40px;
        width: 40px;
        border-radius: 0 0 150px 150px;
        background-color: $lightred;
    }

.cuphandle {
  position: absolute;
  top: 15px;
  left: 50px;
  height: 40px;
  width: 40px;
  background: $lightred;
  clip-path: ellipse(40% 25% at 50% 50%);
  transform: rotate(320deg);
};

.cuphandlein {
  position: absolute;
  top: 28px;
  left: 53px;
  height: 20px;
  width: 30px;
  background: $bg_white;
  clip-path: ellipse(40% 25% at 50% 50%);
  transform: rotate(320deg);
};

.cupinside {
  position: absolute;
  top: 17px;
  left: 25px;
  height: 20px;
  width: 50px;
  background: $darkred;
  clip-path: ellipse(40% 25% at 50% 50%);
  transform: rotate(0deg);
};

.cupbase {
  position: absolute;
  top: 45px;
  left: 30px;
  height: 40px;
  width: 40px;
  background: $darkred;
  clip-path: ellipse(40% 15% at 50% 50%);
  transform: rotate(0deg);
};


 

/** Animations */

@keyframes right_arm {
  0% {
    transform: rotate(-30deg);
  }
  25% {
    transform: rotate(-60deg);
  }
  50% {
    transform: rotate(-35deg);
  }
  75% {
    transform: rotate(-60deg);
  }
  100% {
    transform: rotate(-30deg);
  }
}

@keyframes left_arm {
  0% {
    transform: rotate(-40deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(20deg);
  }
  75% {
    transform: rotate(25deg);
  }
  100% {
    transform: rotate(-40deg);
  }
}

@keyframes right_{
  0% {
    transform: rotate(-40deg);
  }
  10% {
    transform: rotate(-20deg);
  }
  15% {
    transform: rotate(-5deg);
  }
  20% {
    transform: rotate(-60deg);
  }
  25% {
    transform: rotate(-0deg);
  }
  30% {
    transform: rotate(-60deg);
  }
  35% {
    transform: rotate(-0deg);
  }
  40% {
    transform: rotate(-40deg);
  }
  45% {
    transform: rotate(-60deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(-40deg);
  }
}


@keyframes left_finger {
  0% {
    transform: scaleX(0);
  }
  20% {
    transform: scaleX(1) rotate(0deg);
  }
  30% {
    transform: scaleX(1) rotate(8deg);
  }
  40% {
    transform: scaleX(1) rotate(0deg);
  }
  50% {
    transform: scaleX(1) rotate(11deg);
  }
  60% {
    transform: scaleX(1) rotate(0deg);
  }
  70% {
    transform: scaleX(1) rotate(5deg);
  }
  80% {
    transform: scaleX(1) rotate(9deg);
  }
  90% {
    transform: scaleX(1) rotate(0deg);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes right_finger {
  0% {
    transform: scale(0) rotate(0deg);
  }
  20% {
    transform: scale(1) rotate(40deg);
  }
  30% {
    transform: scale(1) rotate(75deg);
  }
  40% {
    transform: scale(1) rotate(90deg);
  }
  60% {
    transform: scale(1) rotate(80deg);
  }
  70% {
    transform: scale(1) rotate(95deg);
  }
  80% {
    transform: scale(1) rotate(80deg);
  }
  90% {
    transform: scale(1) rotate(40deg);
  }
  100% {
    transform: scale(0) rotate(0deg);
  }
};

@keyframes chest_anim {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(7deg);
  }
  50% {
    transform: rotate(3deg);
  }
  75% {
    transform: rotate(7deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes head {
  0% {
    transform: rotate(-1deg);
  }
  25% {
    transform: translatex(15px) rotate(16deg);
  }
  50% {
    transform: translatex(15px) rotate(12deg);
  }
  75% {
    transform: translatex(15px) rotate(16deg);
  }
  100% {
    transform: rotate(-1deg);
  }
};

@keyframes button_2 {
  0% {
    transform: translatex(-1px);
  }
  25% {
    transform: translatex(-5px);
  }
  50% {
    transform: translatex(-1px);
  }
  75% {
     transform: translatex(-5px);
  }
  100% {
     transform: translatex(-1px);
  }
}

@keyframes teapot_float {
  0% {
    transform: translatey(-50px) rotate(0deg);
  }
  25% {
    transform: translatey(-130px) rotate(40deg);
  }
  50% {
    transform: translatey(-125px) rotate(40deg);
  }
  75% {
    transform: translatey(-130px) rotate(20deg);
  }
  100% {
    transform: translatey(-50px) rotate(0deg);
  }
};

@keyframes tray_float {
  0% {
    transform: translatey(-50px) rotate(0deg);
  }
  25% {
    transform: translatey(-50px) rotate(5deg);
  }
  50% {
    transform: translatey(-60px) rotate(-6deg);
  }
  75% {
    transform: translatey(-50px) rotate(10deg);
  }
  100% {
    transform: translatey(-50px) rotate(0deg);
  }
};

@keyframes cup_float {
  0% {
    transform: translatey(-55px) rotate(0deg);
  }
  25% {
    transform: translatey(-90px) rotate(-10deg);
  }
  50% {
    transform: translatey(-100px) rotate(-13deg);
  }
  100% {
    transform: translatey(-55px) rotate(0deg);
  }
};

@keyframes tea_pour {
  20% {
    transform: scale(1)  translatey(0px);
  }
  30% {
    transform: scale(10) translatey(10px);
  } 
  80% {
    transform: scale(10) translatey(30px);
  }
  100% {
    transform: scale(0) translatey(30px);
  }
};

@keyframes tea_float {
  25% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(5px);
  }
};
 