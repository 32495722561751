// Your custom variables

$card-bg: white;

$input-border-width: 0.0rem;
$input-border-color: red;

$font-size-base: 1.0rem !default; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
$h7-font-size: $font-size-base * 0.75 !default;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size
) !default;
$card-border-width: 1px;
$card-border-color: #e3e3e3;

