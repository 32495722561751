//
// Base styles
//

.input-group {
  > .form-control {
    min-height: $input-group-min-height;
    height: $input-group-height;
    padding-top: $input-group-padding-y;
    padding-bottom: $input-group-padding-y;
    transition: $input-group-transition;

    &:focus {
      transition: $input-group-focus-transition;
      border-color: $input-group-focus-border-color;
      outline: 0;
      box-shadow: $input-group-focus-box-shadow;
    }
  }
}

.input-group-text {
  background-color: transparent;
  padding-top: $input-group-text-padding-y;
  padding-bottom: $input-group-text-padding-y;
  > .form-check-input[type='checkbox'] {
    margin-left: 1px;
    margin-right: 1px;
  }
  > .form-check-input[type='radio'] {
    margin-right: 0;
  }
}

.input-group-lg {
  > .form-control {
    height: $input-group-lg-height;
    font-size: $input-group-lg-font-size;
    padding-top: $input-group-lg-padding-y;
    padding-bottom: $input-group-lg-padding-y;
  }

  .input-group-text {
    font-size: $input-group-lg-text-font-size;
  }
}

.input-group-sm {
  > .form-control {
    min-height: $input-group-sm-min-height;
    height: $input-group-sm-height;
    font-size: $input-group-sm-font-size;
    padding-top: $input-group-sm-padding-y;
    padding-bottom: $input-group-sm-padding-y;
  }

  .input-group-text {
    font-size: $input-group-sm-text-font-size;
    line-height: $input-group-sm-text-line-height;
  }
}

.input-group {
  &.form-outline {
    .input-group-text {
      border-left: 0;
    }
    input + .input-group-text {
      border: 0;
      border-left: $input-group-form-outline-border-left-width solid $input-group-form-outline-border-left-color;
    }
  }
  .form-outline:not(:first-child),
  .select-wrapper:not(:first-child),
  .form-outline:not(:first-child) .form-notch-leading,
  .select-wrapper:not(:first-child) .form-notch-leading {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .form-outline:not(:last-child),
  .select-wrapper:not(:last-child),
  .form-outline:not(:last-child) .form-notch-trailing,
  .select-wrapper:not(:last-child) .form-notch-trailing {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.input-group > [class*='btn-outline-'] + [class*='btn-outline-'] {
  border-left: 0;
}

.input-group > .btn[class*='btn-outline-'] {
  padding-top: 0.47rem;
}

.input-group > .btn {
  padding-top: 0.59rem;
}
