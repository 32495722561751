//
// Images
//

.bg-image {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-attachment: fixed;
}

.hover-overlay {
  .mask {
    opacity: 0;
    transition: $image-hover-overlay-transition;
    &:hover {
      opacity: 1;
    }
  }
}

.hover-zoom {
  img,
  video {
    transition: $image-hover-zoom-transition;
  }
  &:hover {
    img,
    video {
      transform: $image-hover-zoom-transform;
    }
  }
}

.hover-shadow,
.card.hover-shadow {
  box-shadow: none;
  transition: $image-hover-shadow-transition;
  &:hover {
    box-shadow: $image-hover-shadow-box-shadow;
    transition: $image-hover-shadow-transition;
  }
}

.bg-fixed {
  background-attachment: fixed;
}

.hover-shadow-soft,
.card.hover-shadow-soft {
  box-shadow: none;
  transition: $image-hover-shadow-transition;
  &:hover {
    box-shadow: $image-hover-shadow-box-shadow-soft;
    transition: $image-hover-shadow-transition;
  }
}
