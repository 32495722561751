.timepicker {
  &-wrapper {
    touch-action: none;
    z-index: $zindex-timepicker;
    opacity: 0;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: $timepicker-wrapper-background-color;
  }

  &-wrapper-inline {
    border-radius: $timepicker-wrapper-inline-border-radius;
  }

  &-elements {
    min-width: $timepicker-elements-min-width;
    min-height: $timepicker-elements-min-height;
    background: $timepicker-elements-background;
    border-top-right-radius: $timepicker-elements-border-top-right-radius;
    border-top-left-radius: $timepicker-elements-border-top-left-radius;

    @media screen and (min-width: 320px) and (max-width: 825px) and (orientation: landscape) {
      flex-direction: row !important;
      border-bottom-left-radius: $timepicker-elements-media-border-bottom-left-radius;
      min-width: auto;
      min-height: auto;
      overflow-y: auto;
    }
  }

  &-elements-inline {
    min-height: auto;
  }

  &-head {
    background-color: $timepicker-head-background-color;
    height: $timepicker-head-height;
    border-top-right-radius: $timepicker-head-border-top-right-radius;
    border-top-left-radius: $timepicker-head-border-top-left-radius;
    padding: $timepicker-head-padding-y $timepicker-head-padding-right $timepicker-head-padding-y
      $timepicker-head-padding-left;

    @media screen and (min-width: 320px) and (max-width: 825px) and (orientation: landscape) {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      padding: $timepicker-head-media-padding;
      padding-right: $timepicker-head-media-padding-right !important;
      height: auto;
      min-height: $timepicker-head-media-min-height;
    }
  }

  &-head-inline {
    padding: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  &-head-content {
    @media screen and (min-width: 320px) and (max-width: 825px) and (orientation: landscape) {
      flex-direction: column;
    }
  }

  &-button {
    font-size: $timepicker-button-font-size;
    min-width: $timepicker-button-min-width;
    box-sizing: border-box;
    font-weight: $timepicker-button-font-weight;
    line-height: $timepicker-button-line-height;
    border-radius: $timepicker-button-border-radius;
    letter-spacing: $timepicker-button-letter-spacing;
    text-transform: uppercase;
    color: $timepicker-button-color;
    border: none;
    background-color: transparent;
    transition: $timepicker-button-transition;
    outline: none;
    padding: 0 $timepicker-button-padding-x;
    height: $timepicker-button-height;
    margin-bottom: $timepicker-button-margin-bottom;

    &:hover {
      background-color: $timepicker-button-hover-background-color;
    }

    &:focus {
      outline: none;
      background-color: $timepicker-button-focus-background-color;
    }
  }

  &-submit-inline {
    border-radius: 100%;
    height: $timepicker-submit-inline-height;
    min-width: $timepicker-submit-inline-min-width;
    display: inline-block;
    margin-left: $timepicker-submit-inline-margin-left;
    color: $timepicker-submit-inline-color;
  }

  &-current {
    font-size: $timepicker-current-font-size;
    font-weight: $timepicker-current-font-weight;
    line-height: $timepicker-current-line-height;
    letter-spacing: $timepicker-current-letter-spacing;
    color: $timepicker-current-color;
    opacity: $timepicker-current-opacity;
    border: none;
    background: transparent;
    padding: 0;

    &.active {
      opacity: 1;
    }

    &-wrapper {
      direction: ltr #{'/*!rtl:ignore*/'};
    }
  }

  &-mode-wrapper {
    font-size: $timepicker-mode-wrapper-font-size;
    color: $timepicker-mode-wrapper-color;

    @media screen and (min-width: 320px) and (max-width: 825px) and (orientation: landscape) {
      justify-content: space-around !important;
      flex-direction: row !important;
    }

    &.active {
      opacity: 1;
    }
  }

  &-clock-wrapper {
    min-width: $timepicker-clock-wrapper-min-width;
    max-width: $timepicker-clock-wrapper-max-width;
    min-height: $timepicker-clock-wrapper-min-height;
    overflow-x: hidden;
    height: 100%;
  }

  &-clock {
    position: relative;
    border-radius: 100%;
    width: $timepicker-clock-width;
    height: $timepicker-clock-height;
    cursor: default;
    margin: 0 auto;
    background-color: $timepicker-clock-background-color;
  }

  &-clock-inner {
    position: absolute;
    top: $timepicker-clock-inner-top;
    left: $timepicker-clock-inner-left;
    transform: $timepicker-clock-inner-transform;
    width: $timepicker-clock-inner-width;
    height: $timepicker-clock-inner-height;
    border-radius: 100%;
  }

  &-time-tips-minutes,
  &-time-tips-inner,
  &-time-tips-hours {
    position: absolute;
    border-radius: 100%;
    width: $timepicker-time-tips-inner-width;
    height: $timepicker-time-tips-inner-height;
    text-align: center;
    cursor: pointer;
    font-size: $timepicker-time-tips-inner-font-size;
    border-radius: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $timepicker-time-tips-inner-font-weight;

    &.active {
      color: $timepicker-time-tips-inner-active-color;
      background-color: $timepicker-time-tips-inner-active-background-color;
      font-weight: $timepicker-time-tips-inner-active-font-weight;
    }

    &:focus {
      outline: none;
    }

    &.disabled {
      color: $timepicker-time-tips-inner-disabled-color;
      pointer-events: none;
      background-color: transparent;
    }

    &::selection {
      background-color: transparent;
    }
  }

  &-current,
  &-dot {
    font-weight: $timepicker-dot-font-weight;
    line-height: $timepicker-dot-line-height;
    letter-spacing: $timepicker-dot-letter-spacing;
    color: $timepicker-dot-color;

    @media screen and (min-width: 320px) and (max-width: 825px) and (orientation: landscape) {
      font-size: $timepicker-dot-media-font-size;
      font-weight: $timepicker-dot-media-font-weight;
    }
  }

  &-dot {
    font-size: $timepicker-dot-font-size;
    opacity: $timepicker-dot-opacity;
    border: none;
    background: transparent;
    padding: 0;
  }

  &-current-inline {
    font-size: $timepicker-current-inline-font-size;
  }

  &-item {
    position: absolute;
    border-radius: 100%;
    width: $timepicker-item-width;
    height: $timepicker-item-height;
    text-align: center;
    padding: $timepicker-item-padding;
    user-select: none;
    cursor: default;
    font-size: $timepicker-item-font-size;
    z-index: 3;
  }

  &-middle-dot {
    top: $timepicker-item-middle-dot-top;
    left: $timepicker-item-middle-dot-left;
    width: $timepicker-item-middle-dot-width;
    height: $timepicker-item-middle-dot-height;
    transform: $timepicker-item-middle-dot-transform;
    border-radius: $timepicker-item-middle-dot-border-radius;
    background-color: $timepicker-item-middle-dot-background-color;
  }

  &-hand-pointer {
    background-color: $timepicker-hand-pointer-background-color;
    bottom: $timepicker-hand-pointer-bottom;
    height: $timepicker-hand-pointer-height;
    left: $timepicker-hand-pointer-left #{'/*!rtl:ignore*/'};
    transform-origin: center bottom 0 #{'/*!rtl:ignore*/'};
    width: $timepicker-hand-pointer-width;
  }

  &-transform {
    transition: $timepicker-transform-transition;
  }

  &-time-tips {
    position: absolute;
    border-radius: 100%;
    width: $timepicker-time-tips-width;
    height: $timepicker-time-tips-height;
    text-align: center;
    cursor: pointer;
    font-size: $timepicker-time-tips-font-size;

    &.active {
      color: $timepicker-time-tips-active-color;
    }
  }

  &-circle {
    top: $timepicker-circle-top;
    left: $timepicker-circle-left;
    width: $timepicker-circle-width;
    border: $timepicker-circle-border-width solid $timepicker-circle-border-color;
    height: $timepicker-circle-height;
    box-sizing: content-box;
    border-radius: 100%;
  }

  &-hour-mode {
    padding: 0;
    background-color: transparent;
    border: none;
    color: $timepicker-hour-mode-color;
    opacity: $timepicker-hour-mode-opacity;
  }

  &-hour-mode,
  &-hour,
  &-minute {
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $timepicker-hour-mode-hover-background-color;
      outline: none;
    }

    &.active {
      color: $timepicker-hour-mode-active-color;
      opacity: 1;
    }

    &-current-inline {
      font-size: $timepicker-hour-mode-font-size;
    }
  }

  &-footer {
    border-bottom-left-radius: $timepicker-footer-border-bottom-left-radius;
    border-bottom-right-radius: $timepicker-footer-border-bottom-right-radius;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $timepicker-footer-height;
    padding-left: $timepicker-footer-padding-x;
    padding-right: $timepicker-footer-padding-x;
    background-color: $timepicker-footer-background-color;
  }

  &-container {
    max-height: $timepicker-container-max-height;
    overflow-y: auto;
    box-shadow: $box-shadow-4;
  }

  &-icon-up,
  &-icon-down {
    opacity: 0;
    transition: $timepicker-icon-up-down-transition;
    cursor: pointer;
    transform: $timepicker-icon-up-down-transform;
    left: $timepicker-icon-up-down-left;

    width: $timepicker-icon-up-down-width;
    height: $timepicker-icon-up-down-height;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active,
    &:hover {
      opacity: 1;
    }
  }

  &-icon-up {
    top: $timepicker-icon-up-top;
  }

  &-icon-down {
    bottom: $timepicker-icon-down-bottom;
  }

  &-inline-hour-icons,
  &-inline-minutes-icons {
    button {
      opacity: 1 !important;
      cursor: default;

      &:hover {
        background-color: unset !important;
      }
    }
  }

  &-clock-animation {
    animation: show-up-clock 350ms linear;
  }

  &-icon-btn {
    width: $timepicker-icon-btn-width;
    height: $timepicker-icon-btn-height;
  }

  &-toggle-button {
    position: absolute;
    outline: none;
    border: none;
    background-color: transparent;
    right: $timepicker-btn-icon-right;
    top: $timepicker-btn-icon-top;
    transform: $timepicker-btn-icon-transform;
    transition: $timepicker-btn-icon-transition;
    cursor: pointer;

    &:hover {
      color: $timepicker-btn-icon-hover-color;
    }

    &:focus {
      color: $timepicker-btn-icon-focus-color;
    }
  }

  &-input:focus + &-toggle-button,
  &-input:focus + &-toggle-button i {
    color: $timepicker-input-color;
  }

  a.timepicker-toggle-button,
  &-toggle-button.timepicker-icon {
    right: 1px;
  }
}

@keyframes show-up-clock {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.timepicker-modal {
  z-index: $zindex-timepicker;

  .fade {
    &.show {
      opacity: 1;
    }
  }
}
