// Accordion

.accordion-button {
  &:not(.collapsed) {
    &:focus {
      box-shadow: $accordion-button-focus-box-shadow;
    }
  }

  &:focus {
    border-color: $accordion-button-focus-border-color;
    outline: 0;
    box-shadow: none;
  }
}
