.tableWrap {
    height: 202px;
    overflow: auto;
    border: 1px solid #e3e3e3;
    border-collapse: collapse;
    padding: 0px;
    /* Set header to stick to the top of the container. */
    thead tr th {
      position: sticky;
      top: 0;
      border-bottom: 1px solid gray;
    }
  
    /* Because we must set sticky on th,
       we have to apply background styles here
       rather than on thead */
    th {
      font-weight: bold;
      background: white !important;
      text-align: left;
      /* With border-collapse, we must use box-shadow or psuedo elements
        for the header borders */
      box-shadow: 0px 0px 0 2px #e8e8e8;
    }
  
    table {
      width: 100%;
      font-family: sans-serif;
    }
    table td {
      padding: 16px;
    }
    tbody tr {
      border-bottom: 2px solid #e8e8e8;
    }
    thead {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
    tbody tr:hover {
      background: #e6f7ff;
    }
  }
  
  .section .form-outline {
    width: 100%;
  }
  .andOrSelector {
    width: 300px;
    margin-right: auto;
    margin-left: auto;
  }
  .criteriaFieldSelect {
    min-width: 300px;
    height: 410px;
    .treeview {
      overflow-y: auto;
      height: 300px;
    }
    .treeview ul {
      margin: 0;
      padding: 0;
    }
    ul.collapse {
      margin-left: 1rem;
    }
  }
  