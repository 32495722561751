

.frequency-input {
  width: 60px;
  margin-top: -2px;
  padding: 0;
  .form-outline {
    width: 100%;
  }

  .form-control {
    height: 30px;
  }
}
.time-input {
  width: 130px;
  margin-top: -2px;
  padding: 0;
  .form-outline {
    width: 100%;
    .form-control {
      height: 30px;
      ~ .form-label {
          top: -3px;
      }
    }
  }
} 
.nav-tabs .nav-link{
    font-size: 10px;
}
.periodPanes .tab-content{
  min-height: 289px;
}