// Type
.note {
  padding: $note-padding;
  border-left: $note-border-width solid;
  border-radius: $note-border-radius;
  strong {
    font-weight: $note-strong-font-weight;
  }
  p {
    font-weight: $note-paragraph-font-weight;
  }
}

@each $name, $color in $note {
  .note-#{$name} {
    background-color: map-get($color, bgc);
    border-color: map-get($color, border-color);
  }
}

.w-responsive {
  @media (min-width: 1199px) {
    width: 75%;
  }
}
