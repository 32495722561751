.createBtn{
    width: 70%; 
}
.createBtn.full{
    width: 100%; 
}
.createBtn.inactive {
    display: none !important;
}
.editBtn{
    width: 29%;
    margin-left: 1%;
    
}
.editBtn.active {
    width: 100% !important;
    margin-left: 0;
}
.cancelBtns{
    width: 49.5%; 
}