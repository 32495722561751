// Your custom styles
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
.dropdown-menu{
    z-index: 1020 !important;
}
.wysiwyg{
    margin-top: -25px;
}
.section .wysiwyg-content{
    min-height: 405px;
}
html{
    overflow-x: hidden;
}
body{
    background-color: rgb(245, 245, 245);
}
.profile-picture-top{
    height: 30px;
}
@keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
        opacity: 0;  
        z-index: -1;
    } 
  }
  
  .fade-out {
    animation-name: fade-out;
  }