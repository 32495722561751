.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzYgNDEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PGZpbHRlciB4PSItNi45JSIgeT0iLTYuMiUiIHdpZHRoPSIxMTMuOSUiIGhlaWdodD0iMTEyLjUlIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIGlkPSJmaWx0ZXItMSI+PGZlT2Zmc2V0IGR4PSIwIiBkeT0iMSIgaW49IlNvdXJjZUFscGhhIiByZXN1bHQ9InNoYWRvd09mZnNldE91dGVyMSI+PC9mZU9mZnNldD48ZmVDb2xvck1hdHJpeCB2YWx1ZXM9IjAgMCAwIDAgMSAgIDAgMCAwIDAgMSAgIDAgMCAwIDAgMSAgMCAwIDAgMC41IDAiIHR5cGU9Im1hdHJpeCIgaW49InNoYWRvd09mZnNldE91dGVyMSIgcmVzdWx0PSJzaGFkb3dNYXRyaXhPdXRlcjEiPjwvZmVDb2xvck1hdHJpeD48ZmVNZXJnZT48ZmVNZXJnZU5vZGUgaW49InNoYWRvd01hdHJpeE91dGVyMSI+PC9mZU1lcmdlTm9kZT48ZmVNZXJnZU5vZGUgaW49IlNvdXJjZUdyYXBoaWMiPjwvZmVNZXJnZU5vZGU+PC9mZU1lcmdlPjwvZmlsdGVyPjwvZGVmcz48ZyBpZD0iUHJvamVjdC1TYW1wbGUtVUkiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxnIGlkPSJIb21lLUxvZ2dlZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI0Ny4wMDAwMDAsIC0yOC4wMDAwMDApIj48ZyBpZD0iSGVhZGVyIj48ZyBpZD0iTG9nbyIgZmlsdGVyPSJ1cmwoI2ZpbHRlci0xKSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjQ3LjAwMDAwMCwgMjguMDAwMDAwKSI+PHBhdGggZD0iTTMxLjA3NzIzMjQsMCBMMTcuOTk5NTI4NywwIEw0LjkxOTYzMTUyLDAgTDAuODc4MDkwMjU4LDEyLjM1OTU2MjUgQy0xLjU3OTczNzE0LDE5Ljk0OTAyODQgMS4yODcxNzk3OCwyNy45Mzc3NzIxIDcuNDE2OTQyMDksMzIuMzYwNjI0NCBMMTcuOTk3MzM1Miw0MCBMMjguNTgxMDE4NiwzMi4zNTk1NjI1IEMzNS4wNDA5MDQsMjcuNjk1NjU2OCAzNy40NjAzNDUsMTkuNTU5MzA3NiAzNS4xMzQxMjgyLDEyLjM5Nzc5MTIgQzM1LjEyNDI1NzQsMTIuMzY0ODcyIDMxLjA3NzIzMjQsMCAzMS4wNzcyMzI0LDAiIGlkPSJGaWxsLTQiIGZpbGw9IiMwNDE0MzMiPjwvcGF0aD48cG9seWdvbiBpZD0iRmlsbC02IiBmaWxsPSIjRUZGMUY1IiBwb2ludHM9IjE4IDAgMjIuMDEyOTU0NyAxMi42MDQ3OTE4IDM1IDEyLjYwNDc5MTggMjQuNDkyOTgwMiAyMC4zOTUyMDgyIDI4LjUwNzAxOTggMzMgMTggMjUuMjEwNjY4MiA3LjQ5Mjk4MDIyIDMzIDExLjUwNTkzNDkgMjAuMzk1MjA4MiAxIDEyLjYwNDc5MTggMTMuOTg3MDQ1MyAxMi42MDQ3OTE4Ij48L3BvbHlnb24+PC9nPjwvZz48L2c+PC9nPjwvc3ZnPg==);
	width: 2rem;
	height: 2.25rem;
	background-repeat: no-repeat;
	background-size: cover
}
.logo {
  background-image: url(https://www.doweb.dk/wp-content/uploads/2021/03/DoWEB-logo.png);
  width: 100%;
  height: 50px;
  background-position: center;
  background-size: contain;
}

footer {
	background: #eff1f5;
	box-shadow: 0 -1px 2px 0 #dfe3ec;
	min-height: 132px;
	text-align: center;
	padding: 32px
}

footer .logo {
	margin: 0 auto 10px auto
}

.hero {
	max-width: 500px;
	margin: 0 auto
}

.hero p.lead {
	margin-bottom: 10rem;
	font-size: 1.45rem
}

.hero p.lead a {
	font-weight: 500
}

.hero .app-logo {
	max-width: 7.5rem
}

.next-steps {
	padding: 0 32px
}

.next-steps h2 {
	margin-bottom: 4rem
}

.next-steps .row {
	margin-bottom: 3rem
}

@media (max-width:768px) {
	.next-steps .row {
		margin-bottom: 0
	}
	.next-steps h6 {
		margin-top: 1.5rem
	}
}

pre {
	width: 100%
}

.profile-header {
	text-align: center;
	margin-bottom: 3rem
}

.profile-header .lead {
	font-size: 1.25rem
}

.profile-header .profile-picture {
	box-shadow: 0 0 4px 0 #b3bac7;
	margin-bottom: 1.5rem
}