// Toasts

.toast {
  background-color: $toast-background-color;
  border: 0;
  box-shadow: $toast-box-shadow;

  .btn-close {
    width: 1.3em;
  }
}

.toast-header {
  background-color: $toast-header-background-color;
}

.parent-toast-relative {
  position: relative;
}

.toast-absolute {
  position: absolute;
}

.toast-fixed {
  position: fixed;
  z-index: $zindex-toast;
}
